import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";

type Language = "fr" | "en";

type Translations = {
  [key: string]: {
    fr: string;
    en: string;
  };
};

const translations: Translations = {
  welcome: {
    fr: "Bienvenue",
    en: "Welcome",
  },
  about: {
    fr: "À propos",
    en: "About",
  },
  portfolio: {
    fr: "Portfolio",
    en: "Portfolio",
  },
  skills: {
    fr: "Compétences",
    en: "Skills",
  },
  contact: {
    fr: "Contact",
    en: "Contact",
  },
  freelance: {
    fr: "Freelance",
    en: "Freelance",
  },
  agile: {
    fr: "agile",
    en: "agile",
  },
  forAllYourProjects: {
    fr: "pour tous vos projets",
    en: "for all your projects",
  },
  heroDescription: {
    fr: "Réalisez des projets full-stack avec un développeur freelance expérimenté. Ayant mené à bien de nombreux projets, je vous accompagne de bout en bout.",
    en: "Realize full-stack projects with an experienced freelance developer. Having successfully completed numerous projects, I accompany you from start to finish.",
  },
  sendEmail: {
    fr: "Envoyer un email",
    en: "Send an email",
  },
  scheduleCall: {
    fr: "Réserver un appel",
    en: "Schedule a call",
  },
  profilePictureAlt: {
    fr: "Photo de profil",
    en: "Profile Picture",
  },
  aboutParagraph1: {
    fr: "J'ai travaillé en agence web et accompagné des startups ainsi que des petites entreprises dans la résolution de leurs problématiques informatiques : développement de sites web, applications, back-office, résolution de bugs, implémentation de nouvelles fonctionnalités sur un produit existant, etc.",
    en: "I have worked in a web agency and assisted startups as well as small businesses in solving their IT problems: website development, applications, back-office, bug fixing, implementation of new features on an existing product, etc.",
  },
  aboutParagraph2: {
    fr: "J'ai l'habitude de travailler avec une méthode Agile : points réguliers avec le client, itérations rapides, évolution continue.",
    en: "I am accustomed to working with an Agile method: regular check-ins with the client, rapid iterations, continuous evolution.",
  },
  aboutParagraph3: {
    fr: "Ma stack de prédilection est React / Typescript / NestJS / AWS / Prisma / NextJS / React-Native / Expo.",
    en: "My preferred stack is React / Typescript / NestJS / AWS / Prisma / NextJS / React-Native / Expo.",
  },
  aboutParagraph4: {
    fr: "Je suis diplômé de l'école d'informatique 42 Paris, ainsi que d'une grande école d'ingénieur (ESTP Paris). J'ai également étudié l'entrepreneuriat pendant un mois à HEC Paris, ce qui me donne une compréhension business.",
    en: "I graduated from 42 Paris, a computer science school, as well as from ESTP Paris, an engineering school. I also studied entrepreneurship for a month at HEC Paris, which gives me a business understanding.",
  },
  reviews: {
    fr: "Avis",
    en: "Reviews",
  },
  aymericReview: {
    fr: "Benjamin a été très à l'écoute et très réactif par rapport à mes besoins. Il a su aussi faire de preuve de créativité. Merci à lui",
    en: "Benjamin was very attentive and responsive to my needs. He also demonstrated creativity. Thank you to him",
  },
  anaelleReview: {
    fr: "Benjamin est très à l'écoute et disponible. Il a le sens du détail et n'a pas hésité à retravailler le projet jusqu'à ce que ça me plaise, c'est très appréciable ! Merci :)",
    en: "Benjamin is very attentive and available. He has an eye for detail and didn't hesitate to rework the project until I was satisfied, which is greatly appreciated! Thank you :)",
  },
  jeanReview: {
    fr: "Benjamin a été très efficace sur les projets que nous lui avons confiés, ainsi que très professionnel et impliqué.",
    en: "Benjamin was very efficient on the projects we entrusted to him, as well as very professional and involved.",
  },
  kirillReview: {
    fr: "Benjamin apporte une énergie et une créativité incroyables. Il génère un flux constant d'idées et n'hésite pas à pivoter si nécessaire. Sa capacité à relever les défis est impressionnante, et il est prêt à s'adapter et à trouver de nouvelles solutions rapidement.",
    en: "Benjamin brings incredible energy and creativity. He generates a constant flow of ideas and isn’t afraid to pivot when necessary. His ability to tackle challenges head-on is impressive, and he’s ready to adapt and find new solutions quickly.",
  },
  may2020: {
    fr: "Mai 2020",
    en: "May 2020",
  },
  january2023: {
    fr: "Janvier 2023",
    en: "January 2023",
  },
  september2024: {
    fr: "Septembre 2024",
    en: "September 2024",
  },
  versatilityTitle: {
    fr: "Polyvalence",
    en: "Versatility",
  },
  versatilityDescription: {
    fr: "En tant que développeur full-stack, je maîtrise à la fois les technologies front-end et back-end, vous offrant une solution complète pour votre projet sans avoir besoin de recourir à plusieurs prestataires.",
    en: "As a full-stack developer, I master both front-end and back-end technologies, offering you a complete solution for your project without the need to resort to multiple providers.",
  },
  adaptabilityTitle: {
    fr: "Adaptabilité",
    en: "Adaptability",
  },
  adaptabilityDescription: {
    fr: "Je m'adapte rapidement aux nouvelles technologies et aux exigences de chaque projet, garantissant une solution personnalisée et à jour pour répondre à vos besoins spécifiques.",
    en: "I quickly adapt to new technologies and the requirements of each project, ensuring a customized and up-to-date solution to meet your specific needs.",
  },
  communicationTitle: {
    fr: "Communication fluide",
    en: "Fluid Communication",
  },
  communicationDescription: {
    fr: "Je m'engage à entretenir une communication régulière et transparente avec vous tout au long du projet, vous tenant informé de l'avancement et des éventuels problèmes rencontrés.",
    en: "I am committed to maintaining regular and transparent communication with you throughout the project, keeping you informed of progress and any issues encountered.",
  },
  qualityTitle: {
    fr: "Engagement qualité",
    en: "Quality Commitment",
  },
  qualityDescription: {
    fr: "Je porte une attention particulière à la qualité du code et à l'optimisation des performances, assurant une expérience utilisateur fluide et un site web fiable et sécurisé.",
    en: "I pay particular attention to code quality and performance optimization, ensuring a smooth user experience and a reliable and secure website.",
  },
  innovationTitle: {
    fr: "Esprit d'innovation",
    en: "Spirit of Innovation",
  },
  innovationDescription: {
    fr: "En tant que développeur freelance full-stack, je suis constamment à la recherche de nouvelles approches et technologies pour améliorer l'efficacité et la qualité des projets que je réalise.",
    en: "As a full-stack freelance developer, I am constantly looking for new approaches and technologies to improve the efficiency and quality of the projects I carry out.",
  },
  aiTitle: {
    fr: "IA et performance",
    en: "AI and Performance",
  },
  aiDescription: {
    fr: "J'utilise les technologies d'intelligence artificielle les plus récentes pour optimiser mon flux de travail, améliorer la qualité du code et rester à la pointe des meilleures pratiques de développement.",
    en: "I use the latest artificial intelligence technologies to optimize my workflow, improve code quality, and stay at the forefront of best development practices.",
  },
  featuresTitle: {
    fr: "Nos caractéristiques",
    en: "Our Features",
  },
  featuresDescription: {
    fr: "Découvrez ce qui fait notre force",
    en: "Discover what makes us strong",
  },
  portfolioTitle: {
    fr: "Portfolio",
    en: "Portfolio",
  },
  portfolioSubtitle: {
    fr: "N'hésitez pas à me contacter pour une démonstration personnalisée du portfolio.",
    en: "Feel free to contact me for a personalized demonstration of the portfolio.",
  },
  voilabsTitle: {
    fr: "Voilabs",
    en: "Voilabs",
  },
  voilabsDescription: {
    fr: "Développement d'une solution de chatbot IA avec widget intégrable. Plateforme de personnalisation et suivi des conversations pour le client.",
    en: "Development of an AI chatbot solution with an integrable widget. Customization platform and conversation tracking for the client.",
  },
  uplyTitle: {
    fr: "Uply",
    en: "Uply",
  },
  uplyDescription: {
    fr: "Création d'une webapp de développement personnel. Plateforme d'apprentissage avec évaluations et exercices pour maîtriser de nouvelles compétences.",
    en: "Creation of a personal development webapp. Learning platform with assessments and exercises to master new skills.",
  },
  adStartupTitle: {
    fr: "Startup Publicitaire",
    en: "Advertising Startup",
  },
  adStartupDescription: {
    fr: "Développement complet : back office, portail client et application mobile pour sous-traitants. Solution complète pour une startup dans le domaine de la publicité.",
    en: "Complete development: back office, client portal and mobile application for subcontractors. Complete solution for a startup in the advertising field.",
  },
  documentReceptionTitle: {
    fr: "Réception de Documents",
    en: "Document Reception",
  },
  documentReceptionDescription: {
    fr: "Développement d'un portail client pour le dépôt et la gestion de documents, avec intégration Google Drive. Réalisé avec React et NestJS.",
    en: "Development of a client portal for document submission and management, with Google Drive integration. Built with React and NestJS.",
  },
  hrPlatformTitle: {
    fr: "Plateforme de Gestion RH",
    en: "HR Management Platform",
  },
  hrPlatformDescription: {
    fr: "Création d'une plateforme de gestion du planning interne, avec planification automatique du travail, des congés et suivi des tâches. Développée en React et NestJS.",
    en: "Creation of an internal planning management platform, with automatic work scheduling, leave management and task tracking. Developed in React and NestJS.",
  },
  socialNetworkAppTitle: {
    fr: "App Mobile Réseau Social",
    en: "Social Network Mobile App",
  },
  socialNetworkAppDescription: {
    fr: "Développement d'une application mobile en React Native Expo pour un réseau social avec chat et fonctionnalités d'affiliation pour les posts photos.",
    en: "Development of a mobile application in React Native Expo for a social network with chat and affiliation features for photo posts.",
  },
  aiChatbotLabel: {
    fr: "Chatbot IA",
    en: "AI Chatbot",
  },
  integrableWidgetLabel: {
    fr: "Widget Intégrable",
    en: "Integrable Widget",
  },
  messageHistoryLabel: {
    fr: "Historique Messages",
    en: "Message History",
  },
  personalDevelopmentLabel: {
    fr: "Développement Personnel",
    en: "Personal Development",
  },
  learningLabel: {
    fr: "Apprentissage",
    en: "Learning",
  },
  backOfficeLabel: {
    fr: "Back Office",
    en: "Back Office",
  },
  clientPortalLabel: {
    fr: "Portail Client",
    en: "Client Portal",
  },
  mobileAppLabel: {
    fr: "App Mobile",
    en: "Mobile App",
  },
  documentManagementLabel: {
    fr: "Gestion Documents",
    en: "Document Management",
  },
  googleDriveLabel: {
    fr: "Google Drive",
    en: "Google Drive",
  },
  reactNestjsLabel: {
    fr: "React & NestJS",
    en: "React & NestJS",
  },
  automaticPlanningLabel: {
    fr: "Planning Automatique",
    en: "Automatic Planning",
  },
  leaveManagementLabel: {
    fr: "Gestion Congés",
    en: "Leave Management",
  },
  taskTrackingLabel: {
    fr: "Suivi des Tâches",
    en: "Task Tracking",
  },
  reactNativeExpoLabel: {
    fr: "React Native Expo",
    en: "React Native Expo",
  },
  integratedChatLabel: {
    fr: "Chat Intégré",
    en: "Integrated Chat",
  },
  affiliationLabel: {
    fr: "Affiliation",
    en: "Affiliation",
  },
  skillsTitle: {
    fr: "Compétences",
    en: "Skills",
  },
  rateTitle: {
    fr: "Tarif",
    en: "Rate",
  },
  rateAmount: {
    fr: "500 €/jour",
    en: "500 €/day",
  },
  strengthsTitle: {
    fr: "Forces",
    en: "Strengths",
  },
};

type LanguageContextType = {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (key: string) => string;
};

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<Language>("fr");

  useEffect(() => {
    const detectBrowserLanguage = (): Language => {
      const browserLang = navigator.language.split("-")[0];
      return browserLang === "fr" ? "fr" : "en";
    };

    setLanguage(detectBrowserLanguage());
  }, []);

  const t = (key: string): string => {
    if (translations[key]) {
      return translations[key][language];
    }
    console.warn(`Translation '${key}' for language '${language}' not found.`);
    return key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
