import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Layout from "./Layout";
import VoiLabs from "./Pages/VoiLabs";
import AI from "./Pages/AI";
// Once I will need to implement authentication, I will need to use the following:
// <AuthenticatedRoute>
// </AuthenticatedRoute>

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/voilabs"
          element={
            <Layout>
              <VoiLabs />
            </Layout>
          }
        />
        <Route
          path="/ai"
          element={
            <Layout>
              <AI />
            </Layout>
          }
        />
        <Route
          path="*"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
