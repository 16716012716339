import {
  createStyles,
  Header,
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Anchor,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  // Collapse,
  ScrollArea,
  ActionIcon,
  useMantineColorScheme,
  Tooltip,
} from "@mantine/core";
// import { MantineLogo } from "@mantine/ds";
import { useDisclosure } from "@mantine/hooks";
import {
  IconNotification,
  IconCode,
  IconBook,
  IconChartPie3,
  IconFingerprint,
  IconCoin,
  IconChevronDown,
  IconSun,
  IconMoonStars,
} from "@tabler/icons";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import { useLanguage } from "./languageContext";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: 42,
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md}px ${+theme.spacing.md * 2}px`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  flagIcon: {
    fontSize: "1.2rem",
    lineHeight: 1,
  },
}));

const mockdata = [
  {
    icon: IconCode,
    title: "Open source",
    description: "This Pokémon’s cry is very loud and distracting",
  },
  {
    icon: IconCoin,
    title: "Free for everyone",
    description: "The fluid of Smeargle’s tail secretions changes",
  },
  {
    icon: IconBook,
    title: "Documentation",
    description: "Yanma is capable of seeing 360 degrees without",
  },
  {
    icon: IconFingerprint,
    title: "Security",
    description: "The shell’s rounded shape and the grooves on its.",
  },
  {
    icon: IconChartPie3,
    title: "Analytics",
    description: "This Pokémon uses its flying ability to quickly chase",
  },
  {
    icon: IconNotification,
    title: "Notifications",
    description: "Combusken battles with the intensely hot flames it spews",
  },
];

export default function HeaderBar() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  // const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { language, setLanguage } = useLanguage();
  const { classes, theme } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const voilabs = ["/voilabs", "/ai"].includes(window.location.pathname);

  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group noWrap align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon size={22} color={theme.fn.primaryColor()} />
        </ThemeIcon>
        <div>
          <Text size="sm" weight={500}>
            {item.title}
          </Text>
          <Text size="xs" color="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  const SwitchColorTheme = () => {
    return (
      <ActionIcon
        onClick={() => toggleColorScheme()}
        size="lg"
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
          color:
            theme.colorScheme === "dark"
              ? theme.colors.yellow[4]
              : theme.colors.blue[6],
        })}
      >
        {colorScheme === "dark" ? (
          <IconSun size={18} />
        ) : (
          <IconMoonStars size={18} />
        )}
      </ActionIcon>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const MenuBarFeatures = () => {
    return (
      <HoverCard
        width={600}
        position="bottom"
        radius="md"
        shadow="md"
        withinPortal
      >
        <HoverCard.Target>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className={classes.link}>
            <Center inline>
              <Box component="span" mr={5}>
                Features
              </Box>
              <IconChevronDown size={16} color={theme.fn.primaryColor()} />
            </Center>
          </a>
        </HoverCard.Target>

        <HoverCard.Dropdown sx={{ overflow: "hidden" }}>
          <Group position="apart" px="md">
            <Text weight={500}>Features</Text>
            <Anchor href="#" size="xs">
              View all
            </Anchor>
          </Group>

          <Divider
            my="sm"
            mx="-md"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <SimpleGrid cols={2} spacing={0}>
            {links}
          </SimpleGrid>

          <div className={classes.dropdownFooter}>
            <Group position="apart">
              <div>
                <Text weight={500} size="sm">
                  Get started
                </Text>
                <Text size="xs" color="dimmed">
                  Their food sources have decreased, and their numbers
                </Text>
              </div>
              <Button variant="default">Get started</Button>
            </Group>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    );
  };

  const MainMenuList = () => {
    if (voilabs) return null;
    return (
      <>
        <a href="https://voilabs.com" className={classes.link}>
          Voilabs
        </a>
        <a href="https://uplyapp.com/" className={classes.link}>
          Uply
        </a>
        {/* <NavLink to="/spacex" className={classes.link}>
          SpaceX
        </NavLink>
        <NavLink to="/tesla" className={classes.link}>
          Tesla
        </NavLink>
        <NavLink to="/twitter" className={classes.link}>
          Twitter
        </NavLink> */}
      </>
    );
  };

  const toggleLanguage = () => {
    setLanguage(language === "fr" ? "en" : "fr");
  };

  const LanguageSelector = () => {
    return (
      <Tooltip
        label={language === "fr" ? "Switch to English" : "Passer en Français"}
        withArrow
      >
        <ActionIcon
          onClick={toggleLanguage}
          size="lg"
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
          })}
        >
          <span className={classes.flagIcon}>
            {language === "fr" ? "🇫🇷" : "🇬🇧"}
          </span>
        </ActionIcon>
      </Tooltip>
    );
  };

  const ButtonRightMenu = () => {
    return (
      <>
        {/* <Button variant="default">Log in</Button>
        <Button>Sign up</Button> */}
        <LanguageSelector />
        <SwitchColorTheme />
      </>
    );
  };

  return (
    <Box>
      <Header height={70} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <NavLink
            to={voilabs ? "/voilabs" : "/"}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Logo />
          </NavLink>

          <Group
            sx={{ height: "100%" }}
            spacing={0}
            className={classes.hiddenMobile}
          >
            <MainMenuList />
            <div style={{ width: 160 }} />
            {/* <MenuBarFeatures /> */}
          </Group>

          <Group className={classes.hiddenMobile}>
            <ButtonRightMenu />
          </Group>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
      </Header>

      {/* The Drawer is used for mobile features */}
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea sx={{ height: "calc(100vh - 60px)" }} mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <MainMenuList />

          {/* <UnstyledButton className={classes.link} onClick={toggleLinks}>
              <Center inline>
                <Box component="span" mr={5}>
                  Features
                </Box>
                <IconChevronDown size={16} color={theme.fn.primaryColor()} />
              </Center>
            </UnstyledButton>
            <Collapse in={linksOpened}>{links}</Collapse> */}

          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <Group position="center" grow pb="xl" px="md">
            <ButtonRightMenu />
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
